<template>
	<v-dialog
		v-model="Dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
        <v-card tile>
            <v-toolbar color="#6200ea" dark dense tile flat>
				<v-toolbar-title>{{
					this.$t("global.components.offline.txtTitle")
				}}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
            <Empty
				icon="wifi-off"
				:msg="$t('global.components.offline.txtOffline')"
			/>
        </v-card>
    </v-dialog>
</template>

<script>
const Empty = () =>
	import(/* webpackChunkName: "Empty" */ "@/components/empty.vue");
import { mapMutations } from "vuex";

export default {
    name: 'Offline',
    components: { Empty },
	data: () => ({}),
    computed: {
		Dialog: {
			get: function() {
				return !this.$store.state.ui.online;
			},
			set: function() {
				// this.onlineEvent();
			},
		},
	},
    methods: {
        ...mapMutations("ui", {
			onlineEvent: "onlineEvent",
		}),
    }
};
</script>
